import React, { Fragment, FunctionComponent } from "react";
import styled from "styled-components";
import Grid, { GridItem } from "../components/grid/Grid";
import Header from "../components/header/Header";
import Title from "../components/title/Title";
import Container from "../container/Container";
import Colors from "../tokens/Colors";
import { rem } from "../utils/rem";

const NotFound: FunctionComponent = () => (
  <Fragment>
    <Header />
    <StyledContainer>
      <StyledGrid>
        <GridItem
          start={{
            tablet: 2,
            desktop: 2,
          }}
          span={{
            mobile: 6,
            tablet: 5,
            desktop: 5,
          }}
        >
          <Title color={Colors.WHITE}>
            404 - OH NO’S! WE COULDN’T FIND THAT PAGE :(
          </Title>
        </GridItem>
      </StyledGrid>
    </StyledContainer>
  </Fragment>
);

const StyledGrid = styled(Grid)`
  align-content: center;
`;

const StyledContainer = styled(Container)`
  flex-grow: 1;
  display: flex;
  margin: ${rem(20)} auto;
`;

export default NotFound;
